<div class="map-header-content" fxLayout="column">
    <ng-container 
        *ngIf="isAssetSelected; else elseBlock">
        <app-selected-asset-navigation 
            (closeButtonClicked)="closeButtonClicked()"
            [asset]="asset"
            [subContextView]="subContextView"
            (subContextChange)="handleSubContextChange($event)"
        ></app-selected-asset-navigation>
    </ng-container>

    <ng-template #elseBlock>
        <app-asset-search-form (filterClick)="handleFilter()"></app-asset-search-form>          
    </ng-template>
    <app-map-filter-chip-list></app-map-filter-chip-list>
</div>