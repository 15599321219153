import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject, of, Subject, Observable } from 'rxjs';
import {
  combineLatestWith,
  distinctUntilChanged,
  filter,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { getDriverName, validGpsData } from '@app/modules/shared/utilities/utilities';
import { UiUtilities } from '@app/services/ui-utilities';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { MarkerIconService } from '@app/modules/location/services/marker-icon.service';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { UntypedFormControl } from '@angular/forms';
import { DataDogService } from '@app/services/data-dog.service';
import { DetailsSubcontext, ViewContext } from '@app/store/layout/reducers/layout.reducer';
import { SettingsApiService } from '@app/services/settings-api.service';
import { MapSettings } from '@app/modules/location/models/settings.model';
import { TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/core/services/i18n/translations.service';
import { FiltersState, SortAttribute } from '@app/store/filters/models/filters.model';

import { LocationApiService } from '@app/modules/location-client/location-api.service';
import { Asset, PostBodyConfig } from '@app/modules/location-client/location-api.models';
import { postBodyConfigFromFilters } from '@app/modules/location-client/utilities';
import { environment } from '@environments/environment';
@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetListComponent implements OnInit, OnDestroy {
  @Input() filtersState: Observable<FiltersState>;
  @Output() hoverOut: EventEmitter<object> = new EventEmitter<object>();
  @Output() hoverOver: EventEmitter<object> = new EventEmitter<object>();

  onDestroy$ = new Subject<void>();
  assets: ViewableAsset[] = [];
  assetCount: number = 0;
  assetCountResults: string;
  listTitle: string;

  sortingControl = new UntypedFormControl();
  sortingOptions = [
    { viewValue: 'Asset (A-Z)', sortKey: SortAttribute.ASSET_NAME, sortAsc: true },
    { viewValue: 'Asset (Z-A)', sortKey: SortAttribute.ASSET_NAME, sortAsc: false },
    { viewValue: 'Driver (A-Z)', sortKey: SortAttribute.DRIVER_NAME, sortAsc: true },
    { viewValue: 'Driver (Z-A)', sortKey: SortAttribute.DRIVER_NAME, sortAsc: false },
    { viewValue: 'Newest updates', sortKey: SortAttribute.UPDATES, sortAsc: false },
    { viewValue: 'Oldest updates', sortKey: SortAttribute.UPDATES, sortAsc: true }
  ];
  sortingDefaultValue = this.sortingOptions[0];
  currentSortKey;
  currentSortAsc;

  skeletonMaxCount = 15;
  translated: any;

  // TODO: if we need to vary polling by zoom, push to this behavior subject
  pollingInterval$ = new BehaviorSubject<number>(environment.liveUpdate.pollingInterval);
  postBodyConfig$ = new BehaviorSubject<PostBodyConfig>({ companyId: null });
  pollingCanceller$ = new Subject<Boolean>();

  firstLoadForDD = true;

  // vars for scroll pagination behavior
  listUpdating = false;
  LIST_PAGESIZE = 40;
  LIST_OFFSET_INTERVAL = 20;
  listOffset = 0;
  listUpperOffsetLimit;

  constructor(
    private locationApiService: LocationApiService,
    private locationFacade: LocationFacade,
    public markerIconService: MarkerIconService,
    private changeDetector: ChangeDetectorRef,
    public dataDog: DataDogService,
    private settingsService: SettingsApiService,
    public translations: Translations,
    public translateService: TranslateService,
    private uiUtils: UiUtilities
  ) {}

  ngOnInit(): void {
    this.translations.translationsLoadState
      .pipe(
        filter(loadstate => loadstate === ResourceLoadState.LOAD_SUCCESSFUL),
        mergeMap(_ => {
          return of(
            this.translateService.instant([
              this.translations.appAssetList.assetList,
              this.translations.appAssetList.results,
              this.translations.appAssetList.noResultsFound,
              this.translations.appAssetList.sortBy.assetAsc,
              this.translations.appAssetList.sortBy.assetDesc,
              this.translations.appAssetList.sortBy.driverAsc,
              this.translations.appAssetList.sortBy.driverDesc,
              this.translations.appAssetList.sortBy.oldestUpdates,
              this.translations.appAssetList.sortBy.newestUpdates
            ])
          );
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(translations => {
        this.translated = translations;
        this.sortingOptions[0].viewValue = translations[this.translations.appAssetList.sortBy.assetAsc];
        this.sortingOptions[1].viewValue = translations[this.translations.appAssetList.sortBy.assetDesc];
        this.sortingOptions[2].viewValue = translations[this.translations.appAssetList.sortBy.driverAsc];
        this.sortingOptions[3].viewValue = translations[this.translations.appAssetList.sortBy.driverDesc];
        this.sortingOptions[4].viewValue = translations[this.translations.appAssetList.sortBy.newestUpdates];
        this.sortingOptions[5].viewValue = translations[this.translations.appAssetList.sortBy.oldestUpdates];
      });

    this.settingsService
      .getSetting(MapSettings.MAP_SORT_ORDER)
      .pipe(take(1))
      .subscribe(setting => {
        const initialSetting = this.mapSortSettingToOption(setting.value);
        this.currentSortKey = initialSetting.sortKey;
        this.currentSortAsc = initialSetting.sortAsc;
      });
    this.locationFacade.setViewContext(ViewContext.LIST);
    this.locationFacade.setViewSubContext(DetailsSubcontext.LIVE);

    this.filtersState
      .pipe(
        filter(filters => filters.filter?.company?.id != null),
        distinctUntilChanged((prev, curr) => {
          const prevFilter = { ...prev.filter, northEast: undefined, southWest: undefined };
          const currFilter = { ...curr.filter, northEast: undefined, southWest: undefined };
          return JSON.stringify(prevFilter) === JSON.stringify(currFilter);
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(filters => {
        const filter = filters.filter;
        const sortAsc = filter.sortOrder == null ? this.currentSortAsc : filter.sortOrder === 'asc' ? true : false;
        const sortKey = filter.sortAttribute || this.currentSortKey;
        const selectedOption = this.sortingOptions.find(o => o.sortAsc === sortAsc && o.sortKey === sortKey);
        this.sortingControl.setValue(selectedOption, { emitEvent: false });

        // reset offset when filters change
        this.listOffset = 0;
        this.listUpperOffsetLimit = null;
        // clear assets and display strings
        this.assets = null;
        this.listTitle = null;
        this.assetCountResults = null;
        // stop scroll from changing offset while we wait for new assets
        if (!this.listUpdating) this.listUpdating = true;

        const config = postBodyConfigFromFilters(filters, {
          pageSize: this.LIST_PAGESIZE,
          sortBy: sortKey,
          sortOrder: filter.sortOrder ? filter.sortOrder : this.currentSortAsc ? 'asc' : 'desc'
        });
        // asset list does not take map bounds into account
        delete config?.northEast;
        delete config?.southWest;
        this.postBodyConfig$.next(config);

        // for count, remove things the count request doesn't handle
        const countbody: PostBodyConfig = {
          ...config,
          sortBy: undefined,
          sortOrder: undefined,
          pageSize: undefined,
          offset: undefined
        };

        // get asset count for filters
        this.locationApiService
          .getAssetsCount(countbody)
          .pipe(take(1))
          .subscribe(res => {
            this.assetCount = res.count;
            if (this.assetCount) {
              this.setListUpperOffsetLimit();
              this.listTitle = this.translated[this.translations.appAssetList.assetList];
              this.assetCountResults = `(${this.assetCount} ${
                this.translated[this.translations.appAssetList.results]
              })`;
            } else {
              this.listTitle = this.translated[this.translations.appAssetList.noResultsFound];
            }
          });
      });

    // this is polling mechanism for list
    this.postBodyConfig$
      .pipe(
        filter(pbc => pbc.companyId != null),
        combineLatestWith(this.pollingInterval$),
        tap(() => {
          this.pollingCanceller$.next(true);
        }), // should cancel the prior polling subscription
        switchMap(([p, i]) =>
          this.locationApiService.polling<Asset[]>(
            () => this.locationApiService.getAssets(p),
            i,
            this.pollingCanceller$
          )
        ),
        takeUntil(this.onDestroy$)
      )
      .subscribe((assets: any) => {
        this.assets = (assets as Array<Asset>).map(asset => ({
          ...asset,
          iconUrl: this.markerIconService.fetchAssetsListIconUrl(asset),
          subTitle: this.uiUtils.assetSubtitle(asset),
          className: this.markerIconService.fetchAssetClassName(asset),
          sidebarMessage: this.getTimeAgoString(asset),
          driverName: getDriverName(asset)
        }));
        this.changeDetector.detectChanges();
        if (this.listUpdating) this.listUpdating = false;
      });

    // updates sorting in filter state (which informs postbody) and persistent settings
    this.sortingControl.valueChanges.subscribe(({ sortKey, sortAsc }) => {
      const sorting = {
        sortOrder: sortAsc ? 'asc' : 'desc',
        sortAttribute: sortKey
      };
      this.locationFacade.applySorting(sorting);
      this.saveSortSetting(sortKey, sortAsc);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  decOffsetOnScrollUp() {
    if (this.listOffset > 0 && !this.listUpdating) {
      this.listOffset -= this.LIST_OFFSET_INTERVAL;
      this.postBodyConfig$.pipe(take(1)).subscribe(pbc => {
        this.listUpdating = true;
        this.postBodyConfig$.next({ ...pbc, offset: this.listOffset });
      });
    }
  }

  incOffsetOnScrollDown() {
    if (this.listOffset < this.listUpperOffsetLimit && !this.listUpdating) {
      this.listOffset += this.LIST_OFFSET_INTERVAL;
      this.postBodyConfig$.pipe(take(1)).subscribe(pbc => {
        this.listUpdating = true;
        this.postBodyConfig$.next({ ...pbc, offset: this.listOffset });
      });
    }
  }

  // determines how many results will be on the last page from the API and sets an offset limit,
  // to stop us from making bad requests when we scroll too far down
  setListUpperOffsetLimit() {
    const lastPageCount = this.assetCount % this.LIST_PAGESIZE;
    if (this.assetCount < this.LIST_PAGESIZE) {
      // if there are less assets than one page, don't ever increase offset
      this.listUpperOffsetLimit = 0;
    } else if (lastPageCount > 0 && lastPageCount < this.LIST_OFFSET_INTERVAL) {
      // if last page is too small, keep offset lower so we get close to a full page
      this.listUpperOffsetLimit = this.assetCount - lastPageCount - this.LIST_OFFSET_INTERVAL;
    } else if (lastPageCount > 0) {
      // otherwise the last page will be at least the size of the offset interval
      this.listUpperOffsetLimit = this.assetCount - lastPageCount;
    } else {
      // mod 0 - pages divide evenly into max count
      this.listUpperOffsetLimit = this.assetCount - this.LIST_PAGESIZE;
    }
  }

  loadComplete(): Observable<boolean> {
    const loaded = this.assets != null && this.listTitle != null;
    if (loaded && this.firstLoadForDD) {
      this.dataDog.newRumTiming('asset_list_loaded');
      this.firstLoadForDD = false;
    }
    return of(loaded);
  }

  trackFn(index, value) {
    return value.assetId;
  }

  getTimeAgoString(asset: ViewableAsset): string {
    if (validGpsData(asset)) {
      return this.uiUtils.getTimeAgoString(asset.geoEventTs, false);
    }
    return '';
  }

  mapSortParamsToSetting(sortKey, sortAsc) {
    switch (sortKey) {
      case SortAttribute.ASSET_NAME:
        return sortAsc ? 'ASSET_ASC' : 'ASSET_DESC';
      case SortAttribute.DRIVER_NAME:
        return sortAsc ? 'DRIVER_ASC' : 'DRIVER_DESC';
      case SortAttribute.UPDATES:
        return sortAsc ? 'OLDEST' : 'NEWEST';
    }
  }

  mapSortSettingToOption(settingValue: string) {
    switch (settingValue) {
      case 'ASSET_ASC':
        return this.sortingOptions[0];
      case 'ASSET_DESC':
        return this.sortingOptions[1];
      case 'DRIVER_ASC':
        return this.sortingOptions[2];
      case 'DRIVER_DESC':
        return this.sortingOptions[3];
      case 'NEWEST':
        return this.sortingOptions[4];
      case 'OLDEST':
        return this.sortingOptions[5];
      default:
        return this.sortingDefaultValue;
    }
  }

  saveSortSetting(sortKey: SortAttribute, sortAsc: boolean): void {
    if (sortKey !== this.currentSortKey || sortAsc !== this.currentSortAsc) {
      const settingChoice = this.mapSortParamsToSetting(sortKey, sortAsc);
      this.currentSortKey = sortKey;
      this.currentSortAsc = sortAsc;
      this.settingsService.saveSetting(MapSettings.MAP_SORT_ORDER, settingChoice).pipe(take(1)).subscribe();
    }
  }
}
