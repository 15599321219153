import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { onContentPaneChange } from './../../location.animations';
import { ViewContext } from '@app/store/layout/reducers/layout.reducer';
import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';
import { LoadingAnimationService } from '@app/services/loading-animation.service';
import { takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { LeafletZoneService } from '@app/modules/zones/services/leaflet-zone.service';
import { SharedMapService } from '@app/services/shared-map.service';

@Component({
  selector: 'app-desktop-location-container',
  templateUrl: './desktop-location-container.component.html',
  styleUrls: ['./desktop-location-container.component.scss'],
  animations: [onContentPaneChange],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesktopLocationContainerComponent implements OnInit, OnDestroy {
  @Output()
  handleOpenFilterDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  handleSidenavOpenedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public selectedAsset: Observable<ViewableAsset>;
  public recentPathLoadState = this.locationFacade.getRecentPathLoadState();
  views = ViewContext;
  loading = true;
  onDestroy$ = new Subject<void>();

  constructor(
    public locationFacade: LocationFacade,
    public loadingAnimationService: LoadingAnimationService,
    public leafletZoneService: LeafletZoneService,
    public sharedMapService: SharedMapService
  ) {}

  ngOnInit(): void {
    combineLatest([this.sharedMapService.mapAssetsLoadingState$, this.leafletZoneService.zonesLoadingState$])
      .pipe(
        tap(([mapAssetsLoadState, zonesLoadState]) => {
          this.loading =
            (this.loadingAnimationService.shouldShowAssetsLoadingAnimations &&
              mapAssetsLoadState === ResourceLoadState.LOADING) ||
            zonesLoadState === ResourceLoadState.LOADING;
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
