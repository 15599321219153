import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '@app/store';
import { PlatformFacade } from '@app/modules/platform/facade/platform.facade';

import {
  selectChosenAsset,
  selectIsAssetSelected,
  selectNearbyAssets,
  selectNearbyAssetsEnabled,
  selectNearbyAssetsLoadState,
  selectSelectedAssetLoadingState,
  selectReverseGeocode,
  selectReverseGeocodeStatus
} from '@app/store/asset/selectors/assets.selectors';

import {
  loadSelectedAsset,
  loadSelectedAssetSuccess,
  loadSelectedAssetFailure,
  clearSelectedAsset,
  loadSelectedAssetById,
  clearNearbyAssets
} from '@app/store/asset/actions/assets.actions';

import { ViewContext } from '@app/store/layout/reducers/layout.reducer';
import { selectViewContext } from '@app/store/layout/selectors/layout.selectors';
import { setViewContext } from '@app/store/layout/actions/layout.actions';

import { ViewableAsset } from '@app/modules/location/models/viewable-asset.model';
import { clearDriverProfile } from '@app/store/driver/actions/driver.actions';
import { ResourceLoadState } from '@app/store/filters/models/resource-load.state';

@Injectable({
  providedIn: 'root'
})
export class AssetDetailsFacade {
  constructor(private store: Store<AppState>, private platformFacade: PlatformFacade) {}

  getSelectedAsset(): Observable<ViewableAsset> {
    return this.store.select(selectChosenAsset);
  }

  setSelectedAsset(asset: ViewableAsset): void {
    this.store.dispatch(clearDriverProfile());
    this.store.dispatch(loadSelectedAsset({ selectedAsset: asset }));
  }

  clearNearbyAssets() {
    this.store.dispatch(clearNearbyAssets());
  }

  getNearbyAssets(): Observable<ViewableAsset[]> {
    return this.store.select(selectNearbyAssets);
  }

  getNearbyAssetsLoadState(): Observable<ResourceLoadState> {
    return this.store.select(selectNearbyAssetsLoadState);
  }

  getNearbyAssetsEnabled(): Observable<boolean> {
    return this.store.select(selectNearbyAssetsEnabled);
  }

  setSelectedAssetById(assetId: string) {
    this.store.dispatch(loadSelectedAssetById({ assetId }));
  }

  clearSelectedAsset() {
    return this.store.dispatch(clearSelectedAsset());
  }

  getSelectedAssetLoadState() {
    return this.store.select(selectSelectedAssetLoadingState);
  }

  getReverseGeocode() {
    return this.store.select(selectReverseGeocode);
  }
  getReverseGeocodeStatus() {
    return this.store.select(selectReverseGeocodeStatus);
  }

  getIsAssetSelected() {
    return this.store.select(selectIsAssetSelected);
  }

  getIsMobile() {
    return this.platformFacade.getIsMobile();
  }

  getSelectViewContext() {
    return this.store.select(selectViewContext);
  }

  setViewContext(context: ViewContext) {
    this.store.dispatch(setViewContext({ context }));
  }
}
