// Angular
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';

// RxJS
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

// App
import { LocationFacade } from '@app/modules/location/facade/location.facade';
import { EntityResource } from '@app/store/filters/models/filters.model';

@Component({
  selector: 'app-map-filter-chip-list',
  templateUrl: './map-filter-chip-list.component.html',
  styleUrls: ['./map-filter-chip-list.component.scss']
})
export class MapFilterChipListComponent implements OnInit {
  constructor(private locationFacade: LocationFacade, private changeDetectorRef: ChangeDetectorRef) {}

  filterChipValues: EntityResource[];

  @Output() filterClick = new EventEmitter<boolean>();
  private onDestroy$ = new Subject();

  ngOnInit(): void {
    this.locationFacade
      .getFilterChipLabels()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(chips => {
        this.filterChipValues = chips;
        this.changeDetectorRef.detectChanges();
      });
  }

  handleFilter() {
    this.filterClick.emit(true);
  }

  handleChipListEvent() {
    this.changeDetectorRef.detectChanges();
  }

  handleChipRemoved(chip) {
    this.locationFacade.removeFilter(chip);
  }

  showChipList() {
    return combineLatest([this.locationFacade.getIsMobile(), this.locationFacade.getIsAssetSelected()]).pipe(
      map(([isMobile, isAssetSelected]) => {
        return !isMobile && !isAssetSelected;
      })
    );
  }
}
